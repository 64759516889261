@import "./z-index";

$color-primary: #e8002a;
$color-sidebar:#fa072fe3;
$color-primary-light: #c01c22;
$color-primary-light-withOpacity: #c01c22d7;
$color-tabs-border: #f77d91;
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-light-3: #ccc;
$color-table-border-grey: #ddd;
$color-tabPanel-border: rgba(231, 228, 228, 0.637);
$color-green: #86d342;

$color-grey-dark: #777;
$color-grey-dark-medium: #666;
$disabled-background-gray : #f0f2f4;

$custom-select-selectAndClearButton-theme: #5ca6e7;

$color-submit-button:#6e88f7;


$color-link-blue: #5897fb;
$color-link-pink: #eaeaea;

$body-bg: #eee;
$color-mobile-sidebar-background: rgba(102, 100, 100, 0.5);

$color-Component-theme: $color-submit-button !important;

$color-datePicker-themeColor:$color-Component-theme;

// Define common padding and border radius sizes and more.
$line-height-lg:1.5 !default;
$line-height-sm:1.15 !default;
$border-width:(1 / 16) * 1rem !default;
$border-color:$color-grey-dark !default;
$border-radius:.25rem !default;
$border-radius-lg:.3rem !default;
$border-radius-sm:.2rem !default;

// //CARD PANEL VARIABLES
// $color-cardPanel-header:#ebb885d8;

//COMMON BUTTON PADDING
$button-padding-class: 12px 17px;
$button-padding-class-medium: 8px 17px;
$button-font-size: 0.8rem;


//SUBMIT BUTTON VARIABLES
$button-boxShadow : 0 2px 2px 0 rgba(233, 30, 99, .14),
0 3px 1px -2px rgba(233, 30, 99, .2),
0 1px 5px 0 rgba(233, 30, 99, .12);
$button-boxShadow-active: 0 14px 26px -12px rgba(233, 30, 99, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(233, 30, 99, .2);
$button-border: 1px solid $color-submit-button;
$button-border-radius: 0.25rem;
$submit-button-fontWeight: 800;
$submit-button-padding: 0.375rem 0.75rem;
$submit-button-background: #e91e63;



//GENERIC VARIABLES
$height-100: 100%;
$width-100:100%;
$height-90vh: 90vh;


//FORM-CONTAINER-VARIABLES
$height-minimum-form-container: 10rem;
$padding-form-container: 1.2rem;
$border-radius-form-container: 4px;


//FORM-CONTROL INPUT ELEMENTS STYLES

$margin-bottom-input:1rem;
$inputField-height:1.8rem;
$font-size-subLabel:0.75rem !important;

$form-control-error-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 4px rgba(255, 0, 0, 0.6);
$label-font-weight:500;

// WIDTH RELATED VARIABLES
$min-width-100: 100%;

//HEADING VARIABLES
$page-heading-weight:600;


//NAVBAR

$header-logo-width: 50px;
$header-navbar-height: 5rem;

// mobileView-navbar-collapse
$mobile-accordion-font-size: .8rem;

//MAIN-CONTAINER
$main-container-margin-top: $header-navbar-height;
$main-container-padding: 1rem;

//CARDS-VARIABLES
$card-container-height: 9rem;
$card-container-width: $min-width-100;
$card-container-margin-btm: 12%;

$card-body-padding: 1rem;
$card-icon-size: 3.4rem;

$card-header-font-size: 0.8rem;

$card-title-responsive-font: clamp(.8rem, -1.3vw + 2.333vw, 2.5rem);
$card-title-font-weight: 400;

$card-value-responsive-font: clamp(2rem, -1.3vw + 2.333vw, 2.5rem);

$card-pink-background:rgb(233, 182, 191);

$card-body-inner-background: #fcfcfc;

// SIDEBAR VARIABLES

$sidebar-scroll-background:#fd2e2e;
$sidebar-scrollbar-width:.2rem;
$side-bar-bg-img : url("../../assets/images/bgtexture.jpg") no-repeat #0a0a0a;
// SIDEBAR CONTAINER

$sidebar-min-height: calc(100vh - 66px);
$sidebar-height:100%;

$sidebar-container-width: 4rem;
$sidebar-container-height: $height-100;
$aggregated-sidebar-li-height: 4rem;
$sidebar-backgroundColor: rgba(255, 255, 255, 0.972);
$sidebarContainerBlurValue: 4.5px;

$sidebarLogo-dropShadowValue: drop-shadow(3px 3px 3px $color-primary);

$sidebar-list-width:16rem;
$mainSidebar-mainHeading-size: .9rem;
$mainSidebar-subHeading-size: .8rem;

// SIDEBAR TOGGLE ICON VARIABLES
$sidebar-hamburger-toggle-btn-position-right: -41.8px;
$sidebar-hamburger-toggle-width: 25px;
$sidebar-hamburger-toggle-btn-position-top: 1rem;
$sidebar-closeButton-toggle-position-right:18.2px;


$sidebar-toggle-svg-height: 3rem;
$sidebar-hamburger-toggle-icon-height:1.6rem;
$sidebar-closeButton-toggle-icon-height:1rem;

// SIDEBAR ACTIVE LINKS
// arrow icon

$sidebar-ActiveLink-arrowIcon-size : 1.8rem;
$arrowIcon-position-right: -11px;
$arrowIcon-position-top: -7px;

//MOBILE VIEW
$sidebar-MobileView-ActiveLink-arrowIcon-size : 1.5rem;
$arrowIcon-MobileView-position-right: -6px;
$arrowIcon-MobileView-position-top: -3px;
$sidebar-list-height: 36rem;
$sidebar-mobile-background: $color-mobile-sidebar-background;

// Vertical bar icon

$sidebar-ActiveLink-verticalBarIcon-size : 1rem;
$verticalBarIcon-position-left: -21px;
$verticalBarIcon-position-top: 6px;

//MOBILE VIEW
$verticalBarIcon-MobileView-position-left: -5px;
$verticalBarIcon-MobileView-position-top: 7px;
$sidebar-MobileView-ActiveLink-verticalBarIcon-size : .9rem;


// TOASTER
$toaster-width: 20rem;
$toaster-position-top: 5rem;
$toaster-position-right: 1rem;

$toaster-close-button-top: 0px;
$toaster-close-button-right: 5px;
$toaster-close-button-font-size: 1.8rem;

$toaster-border: 1px solid #ced4da;
$toaster-min-width:20rem;

$toaster-status-icon-size: 4rem;

$toaster-heading-weight: 800;
$toaster-heading-size:1.2rem;

// TABLE VARIABLES

$table-th-td-padding: 1%;
//TABLE WITH BADGES

$table-badge-width:40px;
$table-badge-height:40px;
$table-badge-border-radius: 40%;
$table-badge-line-height:30px;

// DATA TABLE VARIABLES

$dataTable-borderSpacing: 0;



// MODAL VARIABLES

$modal-boxShadow: 0 5px 15px rgba(0, 0, 0, .5);
$modal-borderRadius: 6px;
$modal-header-padding: .7rem;
$modal-body-minHeight: 5rem;
//$modal-footer-padding: 0;



// CUSTOM SELECT STYLE VARIABLES

$selectedOption-background:rgb(230, 230, 230);
$selectedOption-minWidth:0px;
$selectedOption-border-radius:2px;
$selectedOption-margin:2px;
$selectedOption-hover-color:rgba(241, 125, 125, 0.733);
;

$selectedOption-label-color:rgb(51, 51, 51);
$selectedOption-label-padding: 3px 3px 3px 6px;


$deleteSelectedOption-padding: 0px 4px;
$deleteSelectedOption-svg-height:14px;
$deleteSelectedOption-svg-width:14px;


//PAYMENT MAPPING

$paymentMapping-defaultRadio-btn-margin-left-largeScreen: 0rem;
$paymentMapping-defaultRadio-btn-margin-left-smallScreen: 0rem;


//REACT DATE PICKER

$datePicker-timeContainer-border-top-radius:0;

$datePicker-button-month-toggle-button-weight: 900 !important;


// $color-datePicker-themeColor:rgb(194, 215, 248) !important;


//CUSTOM DROP DOWN SELECT BOXES

$dropdown-top-value: 39px !important;
$dropdown-bottom-value: 39px !important;
$dropdown-opening-top-height:230px;


// TABS
$tab-border-top: 5px solid $color-tabPanel-border;
$tab-padding-top: 2rem;

$tab-pseudo-border: 3px solid $color-tabs-border;
$tab-pseudo-border-radius: 4px;
$tab-pseudo-border-position-top:38px;
$tab-pseudo-border-position-left:0;


// SHUTDOWN FORM DATE PICKER

$shutdownForm-datePicker-border: 1px solid rgba(92, 91, 91, 0.602) !important;


// LOADER

$loader-padding-top: 25%;
$loader-background-opacity: .8;

//spinner
$spinner-size: 64px;
$spinner-theme: 5px solid firebrick;


//channel service batch table 

$channel-service-table-width: 7rem;

//material-border
$material-border: 1px solid #ccc;
$material-highlight-border: 2px solid #666;

//material-label-top-font
$material-label-top-font: 0.8rem;

//recommended box height
$recommended-box-height : 260px;

//switch checkbox
$switch-enabled-color: #86d342;
$switch-disabled-color: #97a08e;

//master sprite
$icon-sprite-img : url("../../images/icon_sprite.png") no-repeat;

